<template>
  <div class="invitation-code-config">
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../../base/layout/FixedActionBar";
  import {getInvitationCodeConfig} from "../../api/other-config";
  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: true,
        config: null,
      }
    },
    created() {
      this.getConfig()
    },
    methods: {
      getConfig() {
        getInvitationCodeConfig().then(res => {
          this.config = res.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      saveConfig() {

      }
    },
  }
</script>

<style lang="scss" scoped>
.invitation-code-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #999;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }
}
</style>
